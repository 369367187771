<template>
  <div>
    <nav class="navbar navbar-default navbar-fixed-top">
      <div class="container">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar" />
            <span class="icon-bar" />
            <span class="icon-bar" />
          </button>
          <a
            id="top"
            class="navbar-brand"
          >KNLB Projects</a>
        </div>
        <div
          id="navbar"
          class="navbar-collapse collapse"
        >
          <ul class="nav navbar-nav navbar-right">
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#whyus">Why us?</a></li>
            <li><a href="#services">Our Services</a></li>
            <li><a href="#references">References</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- Section: Splash logo -->
    <div
      id="home"
      class="jumbotron"
    >
      <img
        src="./assets/logo.svg"
        class="splash"
        title="KNLB Projects Pty. Ltd. Logo"
        alt="KNLB Projects Pty. Ltd. Logo"
      >
      <p class="lead">
        An Information Technology Consulting Company<br/>
        We turn your ideas into digital solutions
      </p>
    </div>

    <!-- Section: About Us -->
    <div
      id="about"
      class="container-fluid bg-grey"
    >
      <div class="row slideanim">
        <div class="col-sm-12">
          <h1>About Us</h1>
          <h4>Who we are</h4>
          <p>
            KNLB Projects Pty. Ltd. is an information and communications
            technology consulting company that was established in 2016. We are
            based in Midrand, just north of Johannesburg in South Africa, and
            our core expertise is within software testing and web development.
          </p>
        </div>
      </div>
    </div>

    <!-- Section: Why work with us -->
    <div
      id="whyus"
      class="container-fluid text-center"
    >
      <h1>Why Work With Us?</h1>
      <h4>What we bring</h4>
      <br>
      <div class="row">
        <div class="col-sm-4 slideanim">
          <span class="glyphicon glyphicon-globe logo-small" />
          <h4>EXPERIENCE</h4>
          <p>
            Our staff has professional experience from demanding projects
            in various countries and industries.
          </p>
        </div>
        <div class="col-sm-4 slideanim">
          <span class="glyphicon glyphicon-thumbs-up logo-small" />
          <h4>COMMITMENT</h4>
          <p>
            We want our clients to succeed and do our very best to help you in
            the process.
          </p>
        </div>
        <div class="col-sm-4 slideanim">
          <span class="glyphicon glyphicon-star logo-small" />
          <h4>BEST PRACTICE</h4>
          <p>
            It's an ever-changing world and we take pride in staying on top of
            the development with regards to industry best practices.
          </p>
        </div>
      </div>
      <br><br>
      <div class="row">
        <div class="col-sm-4 slideanim">
          <span class="glyphicon glyphicon-queen logo-small" />
          <h4>BEE Level 1</h4>
          <p>
            As a woman owned exempted micro enterprise rated BEE Level 1 we not
            only bring value to your business by solving your problems, but also
            a 135% procurement recognition.
          </p>
        </div>
        <div class="col-sm-4 slideanim">
          <span class="glyphicon glyphicon-education logo-small" />
          <h4>CERTIFICATION</h4>
          <p>
            You can know what to expect from our services as we strive for our
            consultants to be certified within their main areas of expertise.
          </p>
        </div>
        <div class="col-sm-4 slideanim">
          <span class="glyphicon glyphicon-map-marker logo-small" />
          <h4>SOUTH AFRICAN</h4>
          <p>
            If you value procuring services from a local vendor then we
            are based in Midrand and a proudly South African company.
          </p>
        </div>
      </div>
    </div>

    <!-- Section: Our services -->
    <div
      id="services"
      class="container-fluid text-center bg-grey"
    >
      <div class="row">
        <h1>Our Services</h1>
        <h4>What we do</h4>
        <br>
        <div class="col-sm-4 text-left slideanim">
          <div class="thumbnail">
            <img
              src="./assets/pexels-qa-sm.jpg"
              style="width: 100%; height: auto;"
            >

            <p style="padding: 20px;">
              <strong>Testing</strong><br>
              We have experience in software testing ranging from large and
              heavy infrastructure projects featuring waterfall methodologies to
              pure IT projects in agile Scrum environments.
              <br><br>
              Gone are the days of the tester being a button pusher as we
              believe the modern profile of the position is a more technical
              one; if you're looking for someone technically savvy to fill your
              need as a test analyst / technical test analyst then you need not
              look any further.
            </p>
          </div>
        </div>
        <div class="col-sm-4 text-left slideanim">
          <div class="thumbnail">
            <img
              src="./assets/pexels-ra-sm.jpg"
              style="width: 100%; height: auto;"
            >

            <p style="padding: 20px;">
              <strong>Test Lead / Test Management</strong><br>
              As software projects tend to get more and more complex it also
              gets more important to have control over the risks and resources
              that are present in the project to make sure your team is working
              on the right issues. We can help you have structure in your
              testing process and aid you in making the hard decisions when it
              matters the most.
            </p>
          </div>
        </div>
        <div class="col-sm-4 text-left slideanim">
          <div class="thumbnail">
            <img
              src="./assets/pexels-code-sm.jpg"
              style="width: 100%; height: auto;"
            >

            <p style="padding: 20px;">
              <strong>Development</strong><br>
              Whether you need us to reinforce your development team in order to
              meet the deadlines in your current project or have a project that
              you'd like completely outsourced, we can do it.
              <br>
              Our main development expertise is within web development and the
              LAMP stack in particular, where we use established frameworks for
              backend and frontend such as Django, Laravel, Angular and VueJS. This
              enables rapid development according to best practices and industry
              standards, which makes the end product be of higher quality and
              more maintainable, maximising your return on investment.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Section: References -->
    <div
      id="references"
      class="container-fluid text-center slideanim"
    >
      <div class="row">
        <div class="col-xs-12">
        <h1>References</h1>
        <h4>Clients that we have had the pleasure of working with</h4>


          <!-- Wrapper for slides -->

        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-3">
            <a
              href="http://www.mpq.se"
            ><img
              src="./assets/reference_logo_mpq_software.png"
              class="reference-logo"
              alt="MPQ Software Logo"
              title="MPQ Software"
            ></a>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3">
            <a
              href="https://www.symptoms.se/"
            ><img
              src="./assets/reference_logo_symptoms.png"
              class="reference-logo"
              alt="Symptoms Logo"
              title="Symptoms"
            ></a>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3">
            <a
              href="https://prewoe.com/"
            ><img
              src="./assets/reference_logo_prewoe.png"
              class="reference-logo"
              alt="PreWoe Logo"
              title="PreWoe"
            ></a>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3">
            <img
              src="./assets/reference_logo_vpab.svg"
              class="reference-logo"
              alt="Vastbo Products AB Logo"
              title="Vastbo Products AB"
            >
          </div>
        </div>
        </div>
      </div>
    </div>

    <!-- Section: Contact -->
    <div
      id="contact"
      class="container-fluid bg-grey"
    >
      <div class="row">
        <div class="col-sm-6">
          <h1>Contact</h1>
          <p>
            <span
              class="glyphicon glyphicon-envelope"
              style="font-size: 12px; margin-right: .5em;"
            />
            <a href="mailto:hello@knlb.co.za">hello@knlb.co.za</a><br>
            <span
              class="glyphicon glyphicon-phone"
              style="font-size: 12px; margin-right: .5em;"
            />+27 72 285 4183<br>
          </p>
          <p>
            <strong>Address</strong><br>
            KNLB Projects Pty. Ltd.<br>
            56 Blue Crane Estate 2<br>
            Corner Garden & Mimosa Road<br>
            Summerset, Midrand<br>
            1685 South Africa
          </p>
        </div>
        <div class="col-sm-6 text-center slideanim">
          <!-- <span class="glyphicon glyphicon-phone logo"></span> -->
            <a href="https://www.google.co.za/maps/place/Blue+Crane+2+Estates/@-25.9599662,28.0989079,16z/data=!4m5!3m4!1s0x0:0xc3ecb0ebcf7e92fa!8m2!3d-25.9590467!4d28.1016326">

            <img src="./assets/static_googlemaps_image.png"  class="staticgooglemaps">

            </a>
        </div>
      </div>
    </div>

    <!-- Section: Footer -->
    <footer class="container-fluid bg-grey text-center">
      &copy; 2016-{{ curYear }} KNLB Projects Pty. Ltd.<br>
      <a href="#home">Back to top</a>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {},
  computed: {
    curYear() {
      return new Date().getFullYear();
    }
  },
  mounted() {
    this.setupAnimations();
    this.setupSmoothScroll();
  },
  methods: {
    setupAnimations() {
      $(window).scroll(function() {
        $(".slideanim").each(function() {
          var pos = $(this).offset().top;

          var winTop = $(window).scrollTop();
          if (pos < winTop + 600) {
            $(this).addClass("slide");
          }
        });
      });
    },
    setupSmoothScroll() {
      // Add smooth scrolling to all links in navbar + footer link
      $(".navbar a, footer a[href='#home']").on("click", function(event) {
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
          // Prevent default anchor click behavior
          event.preventDefault();

          // Store hash
          var hash = this.hash;

          // Using jQuery's animate() method to add smooth page scroll
          // The optional number (900) specifies the number of milliseconds it takes to scroll to the specified area
          $("html, body").animate(
            {
              scrollTop: $(hash).offset().top
            },
            900,
            function() {
              // Add hash (#) to URL when done scrolling (default click behavior)
              window.location.hash = hash;
            }
          );
        } // End if
      });
    },
    
  }
};
</script>
<style type="text/css">
h1 {
  color: #0e4c69;
}

.glyphicon {
  opacity: 1;
}

h1,
h2,
h3,
h4 {
  font-family: monospace;
}

body {
  font-family: arial, helvetica, sans-serif;
  color: #666;
  line-height: 1.8;
}

.jumbotron {
  background-color: transparent;
  text-align: center;
}

a.anchor:hover {
  text-decoration: none;
}

.loading {
  font-size: 50px;
  text-align: center;
  margin: 2em 0;
}

.logo {
  color: #0e4c69;
  font-size: 200px;
  opacity: 0.5;
}

.logo-small {
  color: #0e4c69;
  font-size: 50px;
}

.container-fluid {
  padding: 60px 50px;
}

.bg-grey {
  background-color: #f6f6f6;
}

#services .thumbnail {
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0;
}

#services .thumbnail img {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

#references .thumbnail {
  border: none;
  box-shadow: none;
}

.slideanim {
  visibility: hidden;
}
.slide {
  /* The name of the animation */
  animation-name: slide;
  -webkit-animation-name: slide;
  /* The duration of the animation */
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  /* Make the element visible */
  visibility: visible;
}

/* Go from 0% to 100% opacity (see-through) and specify the percentage from when to slide in the element along the Y-axis */
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@-webkit-keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

@media screen and (max-width: 400px) {
  .splash {
    margin-top: 2em;
    width: 320px;
    height: 240px;
  }
  .reference-logo {
    float: left;
    max-height: 40px;
    max-width: 100px;
    margin-bottom: 2em;
  }
}
@media screen and (min-width: 400px) {
  .splash {
    width: 400px;
    height: 300px;
  }
  .reference-logo {
    max-height: 80px;
    max-width: 200px;
    margin: 2em;
  }
}

.staticgooglemaps,
.staticgooglemaps img {
  display: block;
  max-width: 100%;
  width: 800px;
  height: auto;
}

</style>
